/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Global, css } from "@emotion/core";

import Text from './Common/Text/'

import Callpic from '../assets/patriots-images/kidscallout2.png'
import Callpicmobile from '../assets/patriots-images/kidscallout2-mobile.png'
import Close from '../assets/patriots-images/close.svg'


const CalloutWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: #12233d;
  position: relative;
  padding: 15px 0;
  text-align: center;
  top: 0;
  z-index: 1;
  transition: 0.4s;
  display: ${props => props.visible ? 'block' : 'none' };
  pointer-events: none;
  @media(max-width: 619px){
    height: auto;
    padding: 13px 0;
  }
`

const CallImg = styled.img`
  width: 32%;
  display: block;
  margin: 0 auto;
  @media(max-width: 619px){
    display: none;
  }
`
const CallImgMobile = styled.img`
  display: none;
  @media(max-width: 619px){
    width: 90%;
    margin: 5%;
    display: block;
    margin: 0 auto;
  }

`
const CloseImg = styled.img`
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  top: 15px;
  right: 25px;
  pointer-events: all;
  @media(max-width: 619px){
    top: 10px;
    right: 10px;
  }
`

class Callout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
  }

  render(){

    return (
        <CalloutWrapper id="tesr" visible={this.state.show}>
            <CallImg src={Callpic} />
            <CallImgMobile src={Callpicmobile} />
        </CalloutWrapper>
    )
  }
}

export default Callout
