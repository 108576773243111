/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, Link, graphql } from 'gatsby'
import ReactPixel from 'react-facebook-pixel';
import IsMobile from '../../components/common/IsMobile'
import IsDesktop from '../../components/common/IsDesktop'
import styled from '@emotion/styled'
import { Global, css } from "@emotion/core";
import Imgix from "react-imgix"
import FindRetailers from '../../paragraphs/find_retailer_modal.js'
import { Formik } from 'formik';
import Text from '../Common/Text/'
import Input from '../Common/Input/'
import CheckBox from '../Common/CheckBox/'
import SubmitButton from '../Common/SubmitButton/'
import {customerExists, createContestEntry, updateCustomer} from '../Queries/'
import ReactGA from 'react-ga';

import { Tween, Timeline } from 'react-gsap';

import HeaderBackground from '../../assets/patriots-images/header-grey2.jpg'
import HeaderBackgroundMobile from '../../assets/patriots-images/header-grey2-mobile.jpg'
import Boxes from '../../assets/patriots-images/header-boxes2.png'
import BoxesMobile from '../../assets/patriots-images/header-boxes-mobile.png'

import '../../layouts/css/scotties.webflow.css'
import '../../layouts/css/overrides.css'

import FireworkParticle from '../Scripts/fireworks.js'


const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media(max-width: 769px){
    width: 100%;
    display: block;
  }
`

const Container = styled.div(props => ({
  width: props.width,
  padding: props.padding,
  margin: props.margin,
  position: 'relative',
  display: props.display
}))

const SizedContainer = styled(Container)`

  @media(max-width: 769px){

  }
`
const ResponsiveContainer = styled(SizedContainer)`
  @media(max-width: 1024px){
    width: 70%;
  }
  @media(max-width: 769px){
    width: 96%;
    padding: 0 2%;
  }
`

const HeaderContainer = styled.div`
  width: 100%;
  display: block;
  position: relative;
  z-index: 0;
  height: auto;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-position: 0px%;
  background-size: cover;
  transition: 0.5s;
  margin-bottom: 350px;
  @media(max-width: 769px){
    margin-bottom: 200px;
    min-height: ${props => props.modalOn ? '1400px' : 'auto'};
  }
`

const FormSection = styled.div`
  position: relative;
  z-index: 0;
`

const Label = styled.label`
  display: block;
  color: #12233E;
  font-weight: 600;
  font-size: 11pt;
  > span {
    padding-right: 5px !important;
  }
`

const RedSpan = styled.span`
  color: #e23d3f;
  font-weight: bold;
`

const Error = styled.span`
  display: inline;
  color: #ff0000;
  font-size: 10pt;
  font-weight: 300;
  @media(max-width: 769px){
    display: block;
    margin-left: 0;
  }
`



const SmallHeader = styled.h4`
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #12233E;
  letter-spacing: 1.8px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 25px 0 0;
  opacity: 0;
  @media(max-width: 1024px){
    margin-top: 9%;
  }

`
const MediumHeader = styled.h3`
  font-family: museo-sans, sans-serif;
  font-weight: 900;
  letter-spacing: 0.6px;
  font-size: 34px;
  line-height: 34px;
  color: #12233E;
  text-transform: uppercase;
  font-weight: bold;
  margin: 15px 0 -5px 0;
  display: flex;
  @media(max-width: 769px){
    font-size: 24px;
    line-height: 24px;
  }
`
const BigRed = styled.h2`
  font-family: 'NFLPatriots';
  text-transform: uppercase;
  font-weight: 500;
  font-size: 149px;
  color: #E23D3F;
  letter-spacing: 0.3px;
  line-height: 149px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  @media(max-width: 769px){
    font-size: 118px;
    line-height: 118px;

  }
`

const BigRed2 = styled(BigRed)`
`

const BigBlue = styled.h2`
  font-family: 'NFLPatriots';
  font-weight: 500;
  font-size: 70px;
  line-height: 66px;
  color: #12233E;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 220px;
  @media(max-width: 769px){
    margin-left: 165px;
    margin-top: 21px;
    font-size: 48px;
    line-height: 52px;
  }
`

const BigSup = styled.sup`
  font-size: 16pt;
  line-height: 0;
  position: absolute;
  right: 0;
  margin-left: -10px;
`

const Win = styled.div`
  display:flex;
  position: relative;
`

const Letter = styled.div`
  opacity: 0;
`

const Patriots = styled.div`
  display: block;
  @media(max-width: 619px){
    display: inline-block;
  }
`

const Intro = styled.div`
  padding-right: 8px;
`

const FormText = styled.div`
  opacity: 0;
`

const FlyingBoxes = styled.img`
  width: 80%;
  position: absolute;
  z-index: -1;
  top: 58%;
  right: -5%;
  pointer-events: none;
  @media(max-width: 1930px){
    top: 70%;
  }
  @media(max-width: 1480px){
    top: 67%;
  }
  @media(max-width: 1024px){
    width: 75%;
    top: 77%;
  }
  @media(max-width: 919px){
    top: 82%;
  }
  @media(max-width: 769px){
    width: 100%;
    left: 0;
    right: 0;
    top: auto;
    bottom: -100px;
    opacity: ${props => props.modalOn ? '0' : '1'};
  }
`
const HeaderText = styled.div`

`

const FireWorks = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: ${props => props.appState.screens.bannerFinal ? '1' : '0'};
  transition: 1s;
  opacity: 0;
  @media(max-width: 769px){
    transition-delay: 0.5s;
    opacity: ${props => props.appState.screens.bannerFinal ? '1' : '0'}
  }
`

const ThanksHeader = styled.h2`
  font-family: 'NFLPatriots';
  font-weight: 500;
  font-size: 110px;
  display: block;
  margin-top: 11%;
  line-height: 66px;
  color: #E23D3F;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  @media(max-width: 1025px){
    margin-top: 25%;
    font-size: 66px;
  }
  @media(max-width: 769px){
    margin-top: 40%;
    font-size: 66px;
  }
  @media(max-width: 619px){
    margin-top: 50%;
    font-size: 66px;
  }
`

const Spacer = styled.div`
  display: block;
  width: 100%;
  height: 25px;
`

const ThanksText = styled.p`
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  font-size: 30px;
  line-height: 45px;
  color: #fff;
  width: 48%;
  margin: 3% 26%;
  text-align: center;
  @media(max-width: 769px){
    width: 90%;
    margin: 3% 5% 13%;
    font-size: 24px;
  }
`
const ThanksButton = styled.a`
  cursor: pointer;
  width: 300px;
  text-align: center;
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  border: 3px solid #fff;
  border-radius: 5px;
  margin: 0 calc(50% - 150px);
  padding: 15px 50px;
  color: #fff !important;
  > a {
    color: #fff !important;
  }
`

const GhostBack = styled.span`
  width: 100%;
  display: block;
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 95vh;
  padding-bottom: 50px;
  background-image: url(${HeaderBackground});
  background-repeat: no-repeat;
  background-position: 0px%;
  background-size: cover;
  transition: 0.5s;
  margin-bottom: 350px;
  @media(max-width: 1450px){
    height: 110vh;
  }
  @media(max-width: 769px){
    margin-bottom: 200px;
    background-image: url(${HeaderBackgroundMobile});
    height: ${props => props.modalOn ? '900px' : '840px'};
  }
`

const StyledButton = styled.a`
  background-color: #E23D3F;
  padding: 15px 55px;
  color:#fff !important;
  border: 0;
  font-size:11pt;
  border-radius:2px;
  margin:-15px 0 20px;
  cursor:pointer;
  outline:none;
  display: inline-block;
  text-transform: uppercase;
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  text-align: center;
  transition: 0.5s;
  z-index: 9999;
  :hover{
    background-color: #bf2224;
  }
  @media(min-width: 619px) and (max-width: 769px){
    margin-bottom:35px;
    margin-left: ${props => props.adjust ? '0px' : '14px'};
    padding: ${props => props.adjust ? '20px 45px' : '20px 55px'};
  }
  @media(max-width: 619px){
    margin-bottom:35px;
    margin-left: ${props => props.adjust ? '0px' : '5px'};
    padding: ${props => props.adjust ? '20px 45px' : '20px 55px'};
  }
`

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.launchFireworks = this.launchFireworks.bind(this);
    this.state = {
    };
  }

  launchFireworks(){
    (function() {
      let width = window.innerWidth;
      let height = window.innerHeight;
      let particles = [];
      let existingCanvas = document.querySelector('canvas');
      if (existingCanvas) {
        existingCanvas.parentNode.removeChild(existingCanvas);
      }
      // particle canvas
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');

      canvas.id = 'firework-canvas';
      canvas.width = width;
      canvas.height = height;
      canvas.style.display = 'block';
      canvas.style.transition = '0.5s';
      canvas.style.pointerEvents = 'none';
      canvas.style.position = 'absolute';
      canvas.style.zIndex = '0';
      canvas.style.left = '0';
      canvas.style.top = '0';
      canvas.style.width = '100%';
      canvas.style.height = '95vh';
      canvas.style.opacity = '0.3';
      document.body.insertBefore( canvas, document.body.firstChild );

      // on resize
      window.addEventListener( 'resize', e => {
        width = window.innerWidth;
        height = window.innerHeight;
        canvas.width = width;
        canvas.height = height;
      });

      // add particles slowly over time
      const create = () => {
        if ( particles.length > 2 ) return;
        particles.push( new FireworkParticle( context, width, height, 50 ) );
        setTimeout( create, 600 );
      };

      // animation loop
      const loop = () => {
        requestAnimationFrame( loop );
        context.fillStyle = 'rgba(200,200,200, 0.3)';
        context.fillRect( 0, 0, width, height );

        for ( let p of particles ) {
          if ( p.complete() ) p.reset();
          p.update( width, height );
          p.draw();
        }
      };

      // init
      create();
      loop();
    })();
  }

  animateElements(){

    if(typeof document !== 'undefined'){
      const myElement = document.getElementById("form-section")
      const fBoxes = document.getElementById("flying-boxes")
      const fMobile = document.getElementById("form-mobile")
      const tContainer = document.getElementById("thankscontainer")

      if(this.props.appState.controls.modal){
        if(myElement){
          TweenLite.to(myElement, 1, {x:'-100%', duration: 1, ease:'Power2.easeIn'})
        }
        if(fBoxes){
          TweenLite.to(fBoxes, 1, {x:'55%', duration: 1, ease:'Power2.easeIn'})
        }
        if(fMobile){
          TweenLite.to(fMobile, 1, {x:'-110%', duration: 1, ease:'Power2.easeIn'})
        }
      } else if(!this.props.appState.controls.modal && !this.props.appState.screens.bannerFinal) {
        if(myElement){
          TweenLite.to(myElement, 1, {x: '0%', duration: 1, ease:'Power2.easeIn'})
        }
        if(fBoxes){
          TweenLite.to(fBoxes, 1, {x: '0%', duration: 1, ease:'Power2.easeIn'})
        }
        if(fMobile){
          TweenLite.to(fMobile, 1, {x: '0%', duration: 1, ease:'Power2.easeIn'})
        }
      } else if(!this.props.appState.controls.modal && this.props.appState.screens.bannerFinal) {
        if(fBoxes){
          TweenLite.to(fBoxes, 1, {x: '-20%', duration: 1, ease:'Power2.easeIn'})
        }
        if(myElement){
          TweenLite.to(myElement, 1, {x:'-100%', duration: 1, ease:'Power2.easeIn'})
        }
      }
      if(tContainer && this.props.appState.screens.bannerFinal){
        TweenLite.to(tContainer, 1, {opacity: '1', duration: 1, ease:'Power2.easeIn'})
      }
    }
  }

  reUpdateCustomer(){
    let state = this.props.appState
    state.validations.couponDownloaded = 'SC_PAT_2019'
    if(!state.tracking.coupon){
      ReactGA.pageview('/patriots-2019-coupon-download')
      state.tracking.coupon = true
      this.props.setValue('tracking',state.tracking)
    }
    this.props.setValue('validations',state.validations,() => {
      updateCustomer(this.props.appState,false)
    })

  }

  render(){

    if(this.props.appState.screens.bannerFinal){
      this.launchFireworks()

      ReactPixel.track( 'Purchase', {} )
    }

    this.animateElements()


    return (
      <React.Fragment>
        <IsDesktop>
          <GhostBack />
            <HeaderContainer id="hc">
                <FireWorks appState={this.props.appState} id="thankscontainer">
                  <ThanksHeader>Thank<span style={{'margin-left':'30px'}}>you</span></ThanksHeader>
                    <SizedContainer
                      width="20%"
                      margin="0 40% 0"
                      padding="30px 0 5px"
                      >
                        { this.props.appState.validations.couponDownloaded != 'SC_PAT_2019' ?
                          <Text
                            textColor="#12233E"
                            center={true}>
                            Don’t forget your <RedSpan>COUPON</RedSpan> so you can <RedSpan>SAVE</RedSpan> on Scotties tissue right away!
                          </Text>
                        :
                          ''
                        }
                    </SizedContainer>
                    <Spacer />
                    { this.props.appState.validations.couponDownloaded != 'SC_PAT_2019' ?
                        <StyledButton onClick={() => { this.reUpdateCustomer() }} href="/coupon" target="_blank">Get Coupon</StyledButton>
                        :
                        ''
                    }
                </FireWorks>


              <SizedContainer
                width="80%"
                margin="0 9% 0 11%"
                padding="8.5% 0 0"
                >
                <FormSection id="form-section">
                <HeaderText>
                  <Timeline
                    target={
                      <SmallHeader>
                        The official tissue of the<br/>New England Patriots
                      </SmallHeader>
                      }
                    >
                    <Tween from={{ opacity:0 }} to={{ opacity: 1 }} duration={2.1} ease="Power2.easeOut"/>
                  </Timeline>
                </HeaderText>

                <MediumHeader>
                  <Timeline
                    target={
                      <Intro>Enter</Intro>
                    }
                  >

                    <Tween from={{ opacity: 0, scaleX: 1.1  }} to={{ opacity: 1, scaleX: 1 }} duration={0.21} ease="Power2.easeIn"/>
                  </Timeline>

                  <Timeline
                    target={
                      <Intro>for</Intro>
                    }
                  >
                    <Tween from={{ opacity: 0, scaleX: 1.1  }} to={{ opacity: 1, scaleX: 1 }} delay={0.14} duration={0.21} ease="Power2.easeIn"/>
                  </Timeline>

                  <Timeline
                    target={
                      <Intro>a</Intro>
                    }
                  >
                    <Tween from={{ opacity: 0, scaleX: 1.1  }} to={{ opacity: 1, scaleX: 1 }} delay={0.28} duration={0.21} ease="Power2.easeIn"/>
                  </Timeline>

                  <Timeline
                    target={
                      <Intro>chance</Intro>
                    }
                  >
                    <Tween from={{ opacity: 0, scaleX: 1.1  }} to={{ opacity: 1, scaleX: 1 }} delay={0.42} duration={0.21} ease="Power2.easeIn"/>
                  </Timeline>

                  <Timeline
                    target={
                      <Intro>to</Intro>
                    }
                  >
                    <Tween from={{ opacity: 0, scaleX: 1.1  }} to={{ opacity: 1, scaleX: 1 }} delay={0.56} duration={0.21} ease="Power2.easeIn"/>
                  </Timeline>

                </MediumHeader>
                <SizedContainer
                  display="flex"
                  width="10%">
                  <BigRed>
                    <Win>
                      <Timeline
                        target={
                          <Letter>W</Letter>
                        }
                      >
                        <Tween from={{ scaleY:1.5, scaleX: 1.5, opacity: 0  }} delay={0.77} to={{ scaleY:1, scaleX: 1, opacity: 1 }} duration={0.35} ease="Power2.easeIn"/>


                      </Timeline>

                      <Timeline
                        target={
                          <Letter>I</Letter>
                        }
                      >
                      <Tween from={{ opacity: 0  }} to={{ opacity: 0 }} duration={0.35} ease="Power2.easeIn"/>
                       <Tween from={{ scaleY:2.5, scaleX: 2.5, opacity: 0  }} to={{ scaleY:1, scaleX: 1, opacity: 1 }} delay={0.49} duration={0.35} ease="Power2.easeIn"/>
                       { /* <Tween from={{ scaleY:1, scaleX: 1, opacity: 1  }} to={{ scaleY:3, scaleX: 3, opacity: 0 }} duration={0.6} ease="Power2.easeOut"/> */ }
                      </Timeline>

                      <Timeline
                        target={
                          <Letter>N</Letter>
                        }
                      >
                       <Tween from={{ opacity: 0  }} to={{ opacity: 0 }} duration={0.35} ease="Power2.easeIn"/>
                       <Tween from={{ scaleY:2.5, scaleX: 2.5, opacity: 0  }} to={{ scaleY:1, scaleX: 1, opacity: 1 }} delay={0.63} duration={0.35} ease="Power2.easeIn"/>
                       { /* <Tween from={{ scaleY:1, scaleX: 1, opacity: 1  }} to={{ scaleY:2.2, scaleX: 2.2, opacity: 0 }} duration={0.6} ease="Power2.easeOut"/> */ }
                      </Timeline>

                      <Timeline
                        target={
                          <BigSup>*</BigSup>
                        }
                      >
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} delay={1.33} duration={0.07} ease="Power2.easeOut"/>
                      </Timeline>
                    </Win>

                  </BigRed>


                </SizedContainer>
                <SizedContainer
                  display="inline-block">
                  <BigBlue>
                    <Timeline
                      target={
                        <Patriots>Patriots</Patriots>
                        }
                      >
                      <Tween from={{ x:200, opacity:0 }} to={{ x: 0, opacity: 1 }} delay={1.19} duration={0.28} ease="Power2.easeOut"/>
                    </Timeline>

                    <Timeline
                      target={
                        <Patriots>Tickets</Patriots>
                        }
                      >
                      <Tween from={{ x:100, opacity:0 }} to={{ x: 0, opacity: 1 }} delay={1.54} duration={0.28} ease="Power2.easeOut"/>
                    </Timeline>

                  </BigBlue>
                </SizedContainer>

                <ResponsiveContainer
                  width="30%"
                  margin="1% 0 0 0.5%"
                  padding="0"
                  >

                  <Timeline
                    target={
                      <FormText>
                        <Text
                          textColor="#12233E">
                          Contest has ended.
                        </Text>
                      </FormText>
                    }
                  >
                    <Tween from={{ opacity:0 }} to={{ opacity: 1 }} delay={1.75} duration={0.35} ease="Power2.easeIn"/>
                  </Timeline>

                </ResponsiveContainer>
                </FormSection>
              </SizedContainer>
              <Timeline
                target={
                  <FlyingBoxes id="flying-boxes" src={Boxes}></FlyingBoxes>
                  }
                >
                <Tween from={{ x:100, opacity:0 }} to={{ x: 0, opacity: 1 }} duration={0.91} ease="Power2.easeOut"/>
              </Timeline>
              { this.props.appState.screens.bannerFinal ?
                <div className="arrow-container">
                  <div className="chevron"></div>
                  <div className="chevron"></div>
                  <div className="chevron"></div>
                </div>
                :
                ''
               }


            </HeaderContainer>
        </IsDesktop>
        <IsMobile>
          <GhostBack modalOn={this.props.appState.controls.modal} />
          <HeaderContainer id="hc">
              <FireWorks appState={this.props.appState} id="thankscontainer">
                <ThanksHeader>Thank you</ThanksHeader>
                  <SizedContainer
                    width="90%"
                    margin="0 5% 0"
                    padding="30px 0 5px"
                    >
                    { this.props.appState.validations.couponDownloaded != 'SC_PAT_2019' ?
                      <Text
                        textColor="#12233E"
                        center={true}>
                        Don’t forget your <RedSpan>COUPON</RedSpan> so you can <RedSpan>SAVE</RedSpan> on Scotties tissue right away!
                      </Text>
                      :
                      ''
                    }
                  </SizedContainer>
                  <Spacer />
                  { this.props.appState.validations.couponDownloaded != 'SC_PAT_2019' ?
                      <StyledButton onClick={() => { this.reUpdateCustomer() }} href="/coupon" target="_blank">Get Coupon</StyledButton>
                      :
                      ''
                  }

              </FireWorks>

            <SizedContainer
              width="100%"
              padding="27% 1% 23% 5%"
              >
              <FormSection id="form-mobile">
              <HeaderText>
                <Timeline
                  target={
                    <SmallHeader>
                      The official tissue of the<br/>New England Patriots
                    </SmallHeader>
                    }
                  >
                  <Tween from={{ opacity:0 }} to={{ opacity: 1 }} duration={3} ease="Power2.easeOut"/>
                </Timeline>
              </HeaderText>

              <MediumHeader>
                <Timeline
                  target={
                    <Intro>Enter</Intro>
                  }
                >

                  <Tween from={{ opacity: 0, scaleX: 1.1  }} to={{ opacity: 1, scaleX: 1 }} duration={0.3} ease="Power2.easeIn"/>
                </Timeline>

                <Timeline
                  target={
                    <Intro>for</Intro>
                  }
                >
                  <Tween from={{ opacity: 0, scaleX: 1.1  }} to={{ opacity: 1, scaleX: 1 }} delay={0.2} duration={0.3} ease="Power2.easeIn"/>
                </Timeline>

                <Timeline
                  target={
                    <Intro>a</Intro>
                  }
                >
                  <Tween from={{ opacity: 0, scaleX: 1.1  }} to={{ opacity: 1, scaleX: 1 }} delay={0.4} duration={0.3} ease="Power2.easeIn"/>
                </Timeline>

                <Timeline
                  target={
                    <Intro>chance</Intro>
                  }
                >
                  <Tween from={{ opacity: 0, scaleX: 1.1  }} to={{ opacity: 1, scaleX: 1 }} delay={0.6} duration={0.3} ease="Power2.easeIn"/>
                </Timeline>

                <Timeline
                  target={
                    <Intro>to</Intro>
                  }
                >
                  <Tween from={{ opacity: 0, scaleX: 1.1  }} to={{ opacity: 1, scaleX: 1 }} delay={0.8} duration={0.3} ease="Power2.easeIn"/>
                </Timeline>

              </MediumHeader>




              <SizedContainer
                display="flex"
                width="10%">
                <BigRed>
                  <Win>
                    <Timeline
                      target={
                        <Letter>W</Letter>
                      }
                    >
                      <Tween from={{ scaleY:1.5, scaleX: 1.5, opacity: 0  }} delay={1.1} to={{ scaleY:1, scaleX: 1, opacity: 1 }} duration={0.5} ease="Power2.easeIn"/>


                    </Timeline>

                    <Timeline
                      target={
                        <Letter>I</Letter>
                      }
                    >
                    <Tween from={{ opacity: 0  }} to={{ opacity: 0 }} duration={0.5} ease="Power2.easeIn"/>
                     <Tween from={{ scaleY:2.5, scaleX: 2.5, opacity: 0  }} to={{ scaleY:1, scaleX: 1, opacity: 1 }} delay={0.7} duration={0.5} ease="Power2.easeIn"/>
                     { /* <Tween from={{ scaleY:1, scaleX: 1, opacity: 1  }} to={{ scaleY:3, scaleX: 3, opacity: 0 }} duration={0.6} ease="Power2.easeOut"/> */ }
                    </Timeline>

                    <Timeline
                      target={
                        <Letter>N</Letter>
                      }
                    >
                     <Tween from={{ opacity: 0  }} to={{ opacity: 0 }} duration={0.5} ease="Power2.easeIn"/>
                     <Tween from={{ scaleY:2.5, scaleX: 2.5, opacity: 0  }} to={{ scaleY:1, scaleX: 1, opacity: 1 }} delay={0.9} duration={0.5} ease="Power2.easeIn"/>
                     { /* <Tween from={{ scaleY:1, scaleX: 1, opacity: 1  }} to={{ scaleY:2.2, scaleX: 2.2, opacity: 0 }} duration={0.6} ease="Power2.easeOut"/> */ }
                    </Timeline>

                    <Timeline
                      target={
                        <BigSup>*</BigSup>
                      }
                    >
                    <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} delay={1.9} duration={0.1} ease="Power2.easeOut"/>
                    </Timeline>
                  </Win>

                </BigRed>


              </SizedContainer>
              <SizedContainer
                display="block"
                width="100%">
                <BigBlue>
                  <Timeline
                    target={
                      <Patriots>Patriots</Patriots>
                      }
                    >
                    <Tween from={{ x:200, opacity:0 }} to={{ x: 0, opacity: 1 }} delay={1.7} duration={0.4} ease="Power2.easeOut"/>
                  </Timeline>

                  <Timeline
                    target={
                      <Patriots>Tickets</Patriots>
                      }
                    >
                    <Tween from={{ x:100, opacity:0 }} to={{ x: 0, opacity: 1 }} delay={2.2} duration={0.4} ease="Power2.easeOut"/>
                  </Timeline>

                </BigBlue>
              </SizedContainer>

              <ResponsiveContainer
                width="100%"
                margin="1% 0 0 0"
                padding="0"
                >

                <Timeline
                  target={
                    <FormText>

                      <Formik
                        initialValues={{email: '', terms: false}}
                        validate={values => {
                          let errors = {};

                          if (!values.email) {
                            errors.email = 'A valid email address is required'
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                          ) {
                            errors.email = 'Please enter a valid email'
                          }
                          if (!values.terms) {
                            errors.terms = 'You must agree to the Official Rules'
                          }
                          return errors;
                        }}
                        onSubmit={(values, {setSubmitting}) => {

                          let state = this.props.appState
                          const that = this
                          state.controls.loader = true
                          this.props.setValue('controls', state.controls)
                          customerExists(values.email, function (result, uuid, todayTotalEntries, totalEntries, roundtable) {
                            if (todayTotalEntries > 0) {
                              state.validations.alreadyEntered = true
                            } else {
                              document.getElementsByTagName("body")[0].classList.add('modalUp')
                              if (result) {
                                state.customer.uuid = uuid
                                if (totalEntries > 0) {
                                  createContestEntry(uuid, state)
                                  state.screens.bannerFinal = true
                                  state.screens.contentFinal = true
                                  state.controls.modal = true
                                  state.validations.numberOfEntries = totalEntries
                                  that.props.setValue('validations', state.validations)
                                  that.props.setValue('screens', state.screens)
                                  document.getElementsByTagName("body")[0].classList.remove('modalUp')
                                  window.scroll({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                  })
                                } else {
                                  state.validations.roundtableMember = roundtable
                                  state.validations.numberOfEntries = totalEntries
                                  state.controls.modal = true
                                  if (!state.tracking.modal1) {
                                    if(that.props.appState.customer.uuid == null){
                                      ReactGA.pageview('/patriots-2019-new-first-modal');
                                    } else {
                                      ReactGA.pageview('/patriots-2019-existing-first-modal');
                                    }
                                    state.tracking.modal1 = true
                                  }

                                }
                              } else {
                                state.customer.uuid = null
                                state.controls.modal = true
                                if (!state.tracking.modal1) {
                                  if(that.props.appState.customer.uuid == null){
                                    ReactGA.pageview('/patriots-2019-new-first-modal');
                                  } else {
                                    ReactGA.pageview('/patriots-2019-existing-first-modal');
                                  }
                                  state.tracking.modal1 = true
                                }
                              }
                              state.customer.email = values.email
                              state.validations.terms = true
                              that.props.setValue('validations', state.validations)
                              that.props.setValue('customer', state.customer)
                              that.props.setValue('controls', state.controls)
                            }
                            state.controls.loader = false
                            that.props.setValue('controls', state.controls)
                            that.props.setValue('tracking', state.tracking)
                          })

                        }}
                      >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                          }) => (
                          <form onSubmit={handleSubmit}>
                            <Label htmlFor="email">
                              Email Address
                            <Error> {errors.email && touched.email && errors.email}</Error>
                            </Label>
                            <Input
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              width="85%"
                            />
                            <Error>{errors.terms && touched.terms && errors.terms}</Error>
                            <CheckBox
                              id="terms"
                              type="checkbox"
                              onChange={handleChange}
                              defaultChecked={values.terms}
                            >

                                <React.Fragment>
                                  I have read and agree to abide by the <a href="/scotties_patriots_official_rules_2019.pdf" target="_blank"><u>Official Rules</u></a>.
                                </React.Fragment>

                            </CheckBox>
                            <br/>
                            <SubmitButton
                              type="submit"
                              disabled={false}
                              adjust={true}>
                              Enter Now
                            </SubmitButton>
                            { this.props.appState.validations.alreadyEntered ?
                              <SizedContainer width="300px">
                                <br />
                                <Error>You have already entered the sweepstakes today. Come back tomorrow for another chance to win!</Error>
                              </SizedContainer>
                              :
                              ''
                             }
                          </form>
                        )}
                      </Formik>
                    </FormText>
                  }
                >
                  <Tween from={{ opacity:0 }} to={{ opacity: 1 }} delay={2.5} duration={0.5} ease="Power2.easeIn"/>
                </Timeline>

              </ResponsiveContainer>
              </FormSection>

            </SizedContainer>
            <FlyingBoxes modalOn={this.props.appState.controls.modal} id="flying-boxes" src={BoxesMobile}></FlyingBoxes>
          </HeaderContainer>
        </IsMobile>
      </React.Fragment>
    )
  }
}

export default Banner
