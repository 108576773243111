/* eslint-disable */
import React from 'react';
import Select from 'react-select'


class HTMLSelect extends React.Component {

  /*
   * Hawk.ca Select field for Contest
   *
   * List of props :
   * label : Field label (String)
   * errorMessage : The error message for this field (String)
   * options : An array of objects containing the options for the select (Array of Objects)
   * required : True if the input is required (Boolean)
   * stateName : Contains the key of the state variable to update on input change(String)
   *
   * State variables :
   * error: true if the field has an error (Boolean)
   * success: true if the fields value has passed validation (Boolean)
   *
   */

  constructor(props){
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      error: false,
      success: false,
      selectedOption: this.props.options[0]
    }
  }

  handleChange(selectedOption){

    if (selectedOption.target) {
      selectedOption = {
        label: selectedOption.target.value,
        value: selectedOption.target.value
      }
    }

    this.setState({
      selectedOption: selectedOption
    });

    if (selectedOption.value == '') {
      this.setState({error: true, success: false})
    } else {
      this.setState({error: false, success: true})
    }
    this.setState({
      selectedOption
    }, () => {
      let state = this.props.appState
      state.customer.state = selectedOption
      this.props.setValue('customer', state.customer)
    })
  }


  render(){

    const customStyles = {
      control: (base, state) => ({
        ...base,
        boxShadow: "none",
        border: (state.isFocused || state.isSelected || state.isHover) ?
                "1px solid #8a8686"
                :
                "1px solid #bebebe",
        'font-weight': '300',
        'font-size': '16px',
        'padding': screen.width < 769 ? '8px 0px' : '7px 0px',
        'color': '#767B8B',
        'margin': screen.width < 769 ? '0 0 34px 0' : '0',
        'width': screen.width < 769 ? '95%' : '79.5%'
        // You can also use state.isFocused to conditionally style based on the focus state
      })
    };

    return(
        <Select
          name="htmlSelect"
          value={this.props.value}
          onChange={this.handleChange}
          styles={customStyles}
          options={this.props.options} />
    )
  }
}

export default HTMLSelect;
