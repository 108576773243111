/* eslint-disable */
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {HttpLink} from 'apollo-link-http';
import gql from 'graphql-tag';
import fetch from 'unfetch';
import swal from 'sweetalert2'
import LogError from '../../helpers/Log'

//declare an ID for the contest
const contestId = '81ec9523-f663-4b07-97c0-73a127e17f7b'
//

// Declare the URL of the graphql server
//const graphQlServer = 'http://sbackend.test/graphql'
//const graphQlServer = 'https://api.royalepromotions.ca/graphql'
const graphQlServer = 'https://api.scottiespromotions.com/graphql'

// Set up the link with the graphql server
const client = new ApolloClient({
  link: new HttpLink({ uri: graphQlServer, fetch: fetch}),
  cache: new InMemoryCache()
});

const customerExists = (email, cb) => {

  client.query({
    query: gql `
      query($email: String, $contest_id: String!) {
        customers(email: $email) {
          uuid
          roundtable_member
          coupon_downloaded
          today_total_contest_entries(contest_id: $contest_id)
          total_contest_entries(contest_id: $contest_id)
        }
      }
    `,
    variables: {
      email: email,
      contest_id: contestId
    }
  }).then((res) => {

    // Remove extra data layer
    const data = res.data

    if (data.customers[0] && data.customers[0].uuid) {

      // The customer exist, pass true, the UUID and today's total contest entries
      if(cb){
        cb(
          true,
          data.customers[0].uuid,
          data.customers[0].today_total_contest_entries,
          data.customers[0].total_contest_entries,
          data.customers[0].roundtable_member,
          data.customers[0].coupon_downloaded
        )
      }

    } else {

      // The customer does not exist
      if(cb){
        cb(
          false,
          null,
          null,
          null,
          null,
          null
        )
      }
    }

  }).catch((error) => {

    LogError('ErrorCode #1: customerExists()', 'customerExists', error, {
      'state': {
        email: email,
        contest_id: contestId
      }
    })
    swal({
      title: 'Error!',
      text: 'Something went wrong (ErrorCode #1: customerExists())',
      type: 'error',
      confirmButtonText: 'Ok'
    })
  })

}


const createCustomer = (appState, callback) => {

  client.mutate({
    mutation: gql`
    mutation (
      $contest_id: String!
      $first_name: String!
      $last_name: String!
      $postal_code: String!
      $address1: String
      $address2: String
      $city: String
      $province: String
      $phone: String
      $birth_year: Int!
      $email: String!
      $locale: String!
      $subscribe: Boolean
      $coupon_downloaded:String
    ) {
      createCustomer(
        contest_id: $contest_id
        first_name: $first_name
        last_name: $last_name
        postal_code: $postal_code
        address1: $address1
        address2: $address2
        city: $city
        province: $province
        phone: $phone
        birth_year: $birth_year
        email: $email
        locale: $locale
        subscribe: $subscribe
        coupon_downloaded: $coupon_downloaded
      ) {
        uuid
      }
    }`,
    variables: {
      contest_id: contestId,
      first_name: appState.customer.firstname,
      last_name: appState.customer.lastname,
      postal_code: appState.customer.zipcode,
      address1: appState.customer.address,
      address2: appState.customer.address2,
      city: appState.customer.city,
      province: appState.customer.state.value,
      phone: appState.customer.phone,
      birth_year: appState.customer.yearofbirth,
      email: appState.customer.email,
      locale: 'en',
      subscribe: appState.validations.newsletter,
      coupon_downloaded: appState.validations.couponDownloaded,
    }
  }).then((res) => {

    // Remove extra data layer
    const data = res.data
    callback(data.createCustomer.uuid)
    createContestEntry(data.createCustomer.uuid,appState)

  }).catch((error) => {
    LogError('ErrorCode #2: createCustomer()', 'createCustomer', error, {
      'state': appState
    })

    swal({
      title: 'Error!',
      text: 'Something went wrong (ErrorCode #2: createCustomer())',
      type: 'error',
      confirmButtonText: 'Ok'
    })
  })

}


const updateCustomer = (appState, createNewEntry) => {

  client.mutate({
    mutation: gql`
    mutation (
      $contest_id: String!
      $uuid: String!
      $first_name: String
      $last_name: String
      $postal_code: String
      $address1: String
      $address2: String
      $city: String
      $province: String
      $phone: String
      $birth_year: Int
      $subscribe: Boolean
      $locale: String!
      $coupon_downloaded:String
    ) {
      updateCustomer(
        contest_id: $contest_id
        uuid: $uuid
        first_name: $first_name
        last_name: $last_name
        postal_code: $postal_code
        address1: $address1
        address2: $address2
        city: $city
        province: $province
        phone: $phone
        birth_year: $birth_year
        subscribe: $subscribe
        locale: $locale
        coupon_downloaded: $coupon_downloaded
      ) {
        uuid
      }
    }`,
    variables: {
      contest_id: contestId,
      uuid: appState.customer.uuid,
      first_name: appState.customer.firstname,
      last_name: appState.customer.lastname,
      postal_code: appState.customer.zipcode,
      address1: appState.customer.address,
      address2: appState.customer.address2,
      city: appState.customer.city,
      province: appState.customer.state.value,
      phone: appState.customer.phone,
      birth_year: appState.customer.yearofbirth,
      subscribe: appState.validations.newsletter,
      locale: 'en',
      coupon_downloaded: appState.validations.couponDownloaded,
    }
  }).then((res) => {
    const data = res.data
    if(createNewEntry){
      createContestEntry(appState.customer.uuid,appState)
    }
  }).catch((error) => {
    LogError('ErrorCode #3: updateCustomer()', 'updateCustomer', error, {
      'state': appState
    })

    swal({
      title: 'Error!',
      text: 'Something went wrong (ErrorCode #3: updateCustomer())',
      type: 'error',
      confirmButtonText: 'Ok'
    })
  })
}

function createContestEntry(uuid,appState){
  client.mutate({
    mutation: gql`
    mutation (
      $contest_id: String!
      $customer_id: String!
      $completed: Boolean!
      $type: String!
      $step: Int!
      $data: String
    ) {
      createEntry(
        contest_id: $contest_id
        customer_id: $customer_id
        completed: $completed
        type: $type
        step: $step
        data: $data
      ) {
        uuid
      }
    }`,
    variables: {
      contest_id: contestId,
      customer_id: uuid,
      completed: true,
      type: 'contest',
      step: 3,
      data: JSON.stringify(appState),
    }
  }).then((result) => {

    result = result.data.createEntry
    sessionStorage.setItem('data',result.uuid);

  }).catch((error) => {
    LogError('ErrorCode #4: createContestEntry()', 'createContestEntry', error, {
      'state': appState
    })

    swal({
      title: 'Error!',
      text: 'Something went wrong (ErrorCode #4: createContestEntry())',
      type: 'error',
      confirmButtonText: 'Ok'
    })
  })
}

export { customerExists, createCustomer, updateCustomer, createContestEntry }
