/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import WalmartEn from "../../assets/images/Walmart_En.png";
import WalmartFr from "../../assets/images/Walmart_Fr.png";

const FormattedMessage = styled.div`
width: 100%;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media(max-width: 769px){
    width: 100%;
    display: block;
  }
`

const Spacer = styled.div`
  display: block;
  width: 100%;
  height: 15px;
`

const Container = styled.div(props => ({
  width: props.width,
  padding: props.padding,
  margin: props.margin,
  position: 'relative'
}))

const SizedContainer = styled(Container)`
  @media(max-width: 769px){
    width: 100%;
    margin: 0;
    padding: 0;
  }
`

const FooterWrapper = styled.div`
  position: relative;
  width: 90%;
  max-width: 1200px;
  margin: 125px auto 0;
`
const SmallPrint = styled.p`
  font-size: 7pt;
  line-height: 10pt;
  color: #797979;
`

const LinksContainer = styled.div`
  width: 100%;
  a {
    display: inline-block;
    text-decoration: none;
    margin-right: 20px;
    font-size: 11pt;

  }
`

const Links = styled.div`
  a{
    font-family: museo-sans, sans-serif;
    font-weight: 300;
    margin: 15px 10px 15px 0;
    @media(max-width:769px){
      display: block;
    }
  }
`

const QuestionsContainer = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10pt;
  color: #2f2f2f;

  a {
    color: #9c9999;
    text-transform: none;
    font-weight: 300;
    font-size: 14pt;
    margin: 0;
  }
  @media(max-width: 769px){
    margin-top: 20px;
  }
`

const Border = styled.div`
  height: 1px;
  width: 100%;
  margin: 35px 0 20px;
  background-color: #e7e4e4;
`

const SocialIcon = styled.img`
  width: 20px;
  margin-right: 10px;
`

const Walmart = styled.img`
  width: 100%;
  margin-top: -4px;
  @media(max-width: 769px){
    width: 40%;
    margin-top: 7px;
  }
`


const Footer = (props) => {

  return (
    <FooterWrapper>


        <LinksContainer>
          <FlexContainer>
            <SizedContainer
              width="65%">

                <a href="https://facebook.com/ScottiesKittensChatons/" target="_blank" rel="noopener noreferrer">
                  <SocialIcon src={Facebook} className="footer-icon" />
                </a>
                <a href="https://instagram.com/theScottieskittens/" target="_blank" rel="noopener noreferrer">
                  <SocialIcon src={Instagram} className="footer-icon" />
                </a>
                <Spacer />
              <Links>
                <a style={{color:'#6c6c6c'}} target="_blank" href={'/user-agreement'}>User agreement</a>
                <a style={{color:'#6c6c6c'}} target="_blank" href={'/privacy-policy'}>Privacy policy</a>
                <a style={{color:'#6c6c6c'}} href="/scotties_patriots_official_rules_2019.pdf" target="_blank">Official Rules</a>
              </Links>
          </SizedContainer>
          <SizedContainer
            width="10%"
            margin="0 2%">
            {props.landingPage ?
              <React.Fragment>
                <a target="_blank" href={props.locale == 'en' ? 'https://www.walmart.ca/en/Scotties/N-1019979' : 'https://www.walmart.ca/fr/Scotties/N-1019979'}>
                  <Walmart src={props.locale == 'en' ? WalmartEn : WalmartFr} />
                </a>
              </React.Fragment>
              :
              ''
            }

          </SizedContainer>
          <SizedContainer
            width="25%">
            <QuestionsContainer>
              QUESTIONS? CONTACT US:
              &nbsp;<a href="mailto:support@scottiesfacial.com">support@scottiesfacial.com</a>
            </QuestionsContainer>
          </SizedContainer>
          </FlexContainer>
        </LinksContainer>

        <Border />
        <React.Fragment>

            <SmallPrint>
              NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. Begins 8/13/19 at 9:00:00 a.m. ET and ends 11/4/19 at 11:59:59 p.m. ET. Open to legal residents of Massachusetts, Rhode Island, New Hampshire, Maine, Vermont, and Connecticut, 18 years of age or older at time of entry. Odds of winning depend on the number of eligible entries received. Void outside of MA, RI, NH, ME, VT, and CT and where prohibited.  Go to www.scottiesfacial.com for Official Rules, which govern, prize details, and how to enter. Sponsor: Irving Consumer Products, Inc., 25 Burlington Mall Rd. 6th Floor Burlington, MA 01803.
            </SmallPrint>
            <SmallPrint>
              <sup>©</sup>2019 Irving Consumer Products, Inc. Scotties<sup>®</sup> is a registered trademark of Kimberly Clark Worldwide, Inc. used under license by Irving Consumer Products, Inc. <sup>TM</sup> trademarks of Irving Consumer Products Limited. Used under license.
            </SmallPrint>

        </React.Fragment>
    </FooterWrapper>
  )
}

export default Footer
