/* eslint-disable */
import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from "@emotion/core";
import Banner from '../contest/Banner/'
import Content from '../contest/Content/'
import Footer from '../contest/Footer/'
import Modal from '../contest/Modal/'
import Layout from '../components/layout'
import Header from '../components/header.js'
import Text from '../contest/Common/Text/'
import SubmitButton from '../contest/Common/SubmitButton/'
import Callout from '../contest/kidscallout.js';

import '../layouts/css/scotties.webflow.css'
import '../layouts/css/overrides.css'

import sendEvent from '../helpers/sendAnalytics';
import Helmet from 'react-helmet'

import Loading from '../assets/images/loader.svg'
import LandingBack from '../assets/patriots-images/landing-background2.png'
import LandingBackMobile from '../assets/patriots-images/landing-background-mobile.png'
//import RedBan from '../assets/patriots-images/landing-redbanner.png'
import IntroImage from '../assets/patriots-images/landing-text.png'
import IntroImageMobile from '../assets/patriots-images/landing-text-2.png'
import Boxes from '../assets/patriots-images/lading-boxes.png'

import Left1 from '../assets/patriots-images/landing-left1.png'
import Left2 from '../assets/patriots-images/landing-left2.png'
import Right1 from '../assets/patriots-images/landing-right1.png'
import Right2 from '../assets/patriots-images/landing-right2.png'

import Retailer1 from '../assets/patriots-images/Walmart.png'
import Retailer2 from '../assets/patriots-images/Shaws.png'
import Retailer3 from '../assets/patriots-images/BJs.png'
import Retailer4 from '../assets/patriots-images/Hannaford.png'
import Retailer5 from '../assets/patriots-images/Market-Basket.png'

import Box1 from '../assets/patriots-images/fbox1.png'
import Box2 from '../assets/patriots-images/fbox2.png'
import Box3 from '../assets/patriots-images/fbox3.png'
import Box4 from '../assets/patriots-images/fbox4.png'

import mBox1 from '../assets/patriots-images/box_a_2.gif'
import mBox2 from '../assets/patriots-images/box_b_2.gif'
import mBox3 from '../assets/patriots-images/box_c_2.gif'
import mBox4 from '../assets/patriots-images/box_d_2.gif'

import Group from '../assets/patriots-images/group.png'

/* Styles */

const LandingWrapper = styled.div`
  display: block;
  width: 100%;
  height: auto;
  overflow-x:hidden;
  background-image: url(${LandingBack});
  background-size: 100%;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  @media(max-width: 769px){
      background-image: url(${LandingBackMobile});
  }
`
const IntroImg = styled.img`
  width: 70%;
  margin: 30% 0 55px;
  display: block;
  @media(max-width: 769px){
    display: none;
  }
`
const IntroImgMobile = styled.img`
  display: none;
  @media(max-width: 769px){
    display: block;
    width: 90%;
    margin: 40% 0 55px;
  }
`
const IntroBox = styled.img`
  position: absolute;
  width: 90%;
  top: 34%;
  right: -20%;
  @media(max-width: 769px){
      position: relative;
      width: 100%;
      transform: scale3d(1.5, 1.5, 1.5);
      top: auto;
      right: auto;
      margin: 25% 0 20px 10%;
  }
`
const RedSpan = styled.span`
  color: #fff;
  font-weight: bold;
`

const LeftImg1 = styled.img`
  width: 100%;
  @media(max-width: 769px){
    margin: 0 16% 0 4%;
    width: 80%;
  }
`
const LeftImg2 = styled.img`
  width: 100%;
`
const RightImg1 = styled.img`
  margin: 33% 0 50px 0;
  max-width: 400px;
  @media(max-width: 769px){
    margin: 0% 0 50px 0;
    max-width: 100%;
    width: 100%;
  }
`
const RightImg2 = styled.img`
  width: 55%;
  margin: 20% 0 0 10%;
  display: block;
  @media(max-width: 769px){
    width: 80%;
    margin: 0% 0 0 10%;
  }
`
const Retailer = styled.img`
  width: 40%;
  max-width: 175px;
  margin: 10px 10px;
  @media(max-width: 619px){
    :last-child {
      margin-left: 41%;
    }
  }
  @media(max-width: 619px){
    :last-child {
      margin-left: 45%;
    }
  }
  @media(max-width: 619px){
    :last-child {
      margin-left: 49%;
    }
  }

`
const Box = styled.img`
  width: 70%;
  margin: 0% 15% 0;
`
const OutButton = styled.a`
 border: 2px solid #E23D3F;
 color: #E23D3F;
 padding: 13px 0;
 margin: 50px 0 0 10%;
 width: 200px;
 text-transform: uppercase;
 display: block;
 font-family: museo-sans, sans-serif;
 font-weight: 500;
 text-align: center;
 cursor: pointer;
 @media(max-width: 769px){
   margin: 50px 0 50px 10%;
 }
`


const Container = styled.div(props => ({
  width: props.width,
  padding: props.padding,
  margin: props.margin,
  position: 'relative',
  display: props.display,
  'flex-wrap':'wrap'
}))

const SizedContainer = styled(Container)`
  @media(max-width: 769px){
    width: 96%;
    margin: 0 2%;
  }
`
const BottomBoxSection = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  margin: 15% 0 0;
  @media(max-width: 769px){
    width: 100%;
    margin: 0;
  }
  div:nth-child(1){
    @media(max-width: 769px){
      width: 100%;
      margin: 0;
      order: 2;
    }
  }
`
const ResponsiveContainer = styled(Container)`
  @media(max-width: 1480px){
    margin: 0 10% 30%
  }
  @media(max-width: 1200px){
    margin: 0 10% 10%
  }
  @media(max-width: 769px){
    margin: 0 5% 20%;
    width: 90%;
    display: block;
  }
`
const PresSection = styled(Container)`
  @media(max-width: 919px){
    margin: 30% 10% 0;
  }
  @media(max-width: 769px){
    margin: 30% 2% 0;
    width: 95%;
  }
`
const BoxSection = styled(Container)`
  @media(max-width: 919px){
    margin: 30% 10% 0;
  }
  @media(max-width: 769px){
    margin: 10% 2% 0;
    width: 95%;
    > div {
      width: 100%;
      margin-bottom: 50px;
    }
  }
`
const StyledButton = styled.a`
  background-color: #E23D3F;
  padding: 15px 55px;
  color:#fff;
  border: 0;
  font-size:11pt;
  border-radius:2px;
  margin:-15px 0 20px;
  cursor:pointer;
  outline:none;
  display: inline-block;
  text-transform: uppercase;
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  transition: 0.5s;
  :hover{
    background-color: #bf2224;
  }
  @media(min-width: 619px) and (max-width: 769px){
    margin-bottom:35px;
    margin-left: ${props => props.adjust ? '0px' : '14px'};
    padding: ${props => props.adjust ? '20px 45px' : '20px 55px'};
  }
  @media(max-width: 619px){
    margin-bottom:35px;
    margin-left: ${props => props.adjust ? '0px' : '5px'};
    padding: ${props => props.adjust ? '20px 45px' : '20px 55px'};
  }
`

const HoverDesktop = styled.span`
  display: block;
  @media(max-width: 800px){
    display: none;
  }
`

const ClickMobile = styled.span`
  display: none;
  @media(max-width: 800px){
    display: block;
  }
`


class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.setValue = this.setValue.bind(this);
    this.hoverBox = this.hoverBox.bind(this);
    this.state = {}
  }

  setValue(key, value, callback){
    let obj = {};
    obj[key] = value;
    callback ? this.setState(obj, callback):this.setState(obj)
  }

  hoverBox(position,effect){

    let boxes = [mBox1,mBox2,mBox3,mBox4]
    let boxes2 = [Box1,Box2,Box3,Box4]

    if(effect == 'over'){
      document.getElementById('box' + position).src = boxes[position - 1]
    } else {
      document.getElementById('box' + position).src = boxes2[position - 1]
    }

  }

  componentDidMount(){

    if(window.location.hash == '#boxes'){
      if(document.getElementById('boxes')){
        var elDistanceToTop = window.pageYOffset + document.getElementById('boxes').getBoundingClientRect().top
        document.getElementById('boxes').scrollIntoView();
      }
    }
  }


  render() {

    return (
      <Layout>
          <LandingWrapper>
            <Header hideBuy={true} whiteVersion={true} hideRetailer={true}></Header>
            <div className="arrow-container">
              <div className="chevron2"></div>
              <div className="chevron2"></div>
              <div className="chevron2"></div>
            </div>
            <ResponsiveContainer
              width="80%"
              margin="0 10%">
              <SizedContainer
                width="50%"
                margin="0"
                display="inline-block">
                <IntroImg src={IntroImage} />
                <IntroImgMobile src={IntroImageMobile} />
                <StyledButton
                  target="_blank"
                  href="/coupon"
                  onclick={() => sendEvent({
                    ga: {
                      category: 'Patriots2019',
                      action: 'Click',
                      label: 'BoxCoupon'
                    }
                  })}
                >Get Coupon</StyledButton>
              </SizedContainer>
              <IntroBox src={Boxes} />
            </ResponsiveContainer>

            <PresSection
              width="80%"
              margin="42% 10% 0"
              display="flex">
              <SizedContainer
                width="40%"
                margin="0"
                display="inline-block">
                <LeftImg1 src={Left1} />
              </SizedContainer>
              <SizedContainer
                width="50%"
                margin="0 0 0 10%"
                padding="50px 0 0 0"
                display="inline-block">
                <RightImg1 src={Right1} />
                  <SizedContainer
                    width="70%"
                    margin="0 50% 0 0"
                    padding="0"
                    display="inline-block">
                    <Text
                      textColor="#12233E"
                      center={false}
                      textSize="13pt"
                      lineHeight="22pt">
                      Find these <RedSpan>LIMITED EDITION</RedSpan> box designs at one of these New England retailers while supplies last:
                    </Text>
                  </SizedContainer>
                  <br />
                  <SizedContainer

                    width="80%">
                    <Retailer src={Retailer1} />
                    <Retailer src={Retailer2} />
                    <Retailer src={Retailer3} />
                    <Retailer src={Retailer4} />
                    <Retailer src={Retailer5} />
                  </SizedContainer>

              </SizedContainer>
            </PresSection>

            <Text
              center={true}
              width="90%"
              margin="10% 0 0;">

              <HoverDesktop>Hover over</HoverDesktop><ClickMobile><br /><br />Click</ClickMobile> to see the full design!
            </Text>
            <BoxSection
              id="boxes"
              width="80%"
              margin="0% 10% 0"
              display="flex">
              <SizedContainer
                width="50%"
                margin="0"
                display="inline-block"
                >
                <Box id="box1" onMouseOver={() => this.hoverBox(1,'over')} onMouseOut={() => this.hoverBox(1,'out')} src={Box1} />
              </SizedContainer>
              <SizedContainer
                width="50%"
                margin="0"
                display="inline-block"
                >
                <Box id="box2" onMouseOver={() => this.hoverBox(2,'over')} onMouseOut={() => this.hoverBox(2,'out')} src={Box2} />
              </SizedContainer>
              <SizedContainer
                width="50%"
                margin="0"
                display="inline-block"
                >
                <Box id="box3" onMouseOver={() => this.hoverBox(3,'over')} onMouseOut={() => this.hoverBox(3,'out')} src={Box3} />
              </SizedContainer>
              <SizedContainer
                width="50%"
                margin="0"
                display="inline-block"
                >
                <Box id="box4" onMouseOver={() => this.hoverBox(4,'over')} onMouseOut={() => this.hoverBox(4,'out')} src={Box4} />
              </SizedContainer>
            </BoxSection>
            <BottomBoxSection>
              <SizedContainer
                width="50%"
                margin="0"
                >
                <LeftImg2 src={Left2} />
              </SizedContainer>
              <SizedContainer
                width="50%"
                margin="0"
                >
                <RightImg2 src={Right2} />
                <OutButton
                  href="/patriotssweeps/"
                  onclick={() => sendEvent({
                    ga: {
                      category: 'Patriots2019',
                      action: 'Click',
                      label: 'EnterNowButton'
                    }
                  })}
                >Enter Now</OutButton>
              </SizedContainer>
            </BottomBoxSection>
            <br /><br /><br />
            <Callout />
            <Footer />
          </LandingWrapper>
      </Layout>
    )
  }
}

export default LandingPage
