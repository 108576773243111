/* eslint-disable */
import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

/*** props ***/

// textType: normal, header
// textSize: custom value in px or pt, default it 12pt;
// textColor: Custom color, default black
// padding: adjust the padding of the element
// margin: adjust the margins of the element
// lineHeight: adjust the line-line
// upperCase: boolean, make text uppercase



const StyledText = styled.p(props => ({
  'font-family': 'museo-sans, sans-serif',
  'font-weight':'300',
  'font-size': props.textSize != '' ? props.textSize : '12pt',
  'color': props.textColor != '' ? props.textColor : '#3c3c3c',
  'letter-spacing': '0',
  'line-height': props.lineHeight != '' ? props.lineHeight : '29px',
  'margin':props.margin,
  'padding':props.padding,
  'text-transform': props.upperCase ? 'uppercase' : 'none',
  'text-align':  props.center ? 'center' : 'left',

}))


const StyledH = styled.p(props => ({
  'font-family': 'museo-sans, sans-serif',
  'font-size': props.textSize != '' ? props.textSize : '18pt',
  'color': props.textColor != '' ? props.textColor : '#3c3c3c',
  'letter-spacing': '0',
  'line-height': props.lineHeight != '' ? props.lineHeight : '29px',
  'font-weight': props.bolder ? '700' : '500',
  'margin':props.margin,
  'padding':props.padding,
  'text-transform': props.upperCase ? 'uppercase' : 'none',
  'text-align':  props.center ? 'center' : 'left',
}))

const StyledHeader = styled(StyledH)`
  @media(max-width: 769px){
    font-size: ${props => props.mobileTextSize != '' ? props.mobileTextSize : props.textSize};
    line-height: ${props => props.mobileTextSize != '' ? props.mobileTextSize : props.lineHeight}
  }
  sup {
    vertical-align: baseline;
    position: relative;
    top: ${props => (props.textSize == '29pt') ? '-0.75em' : (props.textSize == '21pt') ? '-0.40em' : '-0.5em'};
    font-size: ${props => (props.textSize == '29pt' || props.textSize == '21pt') ? '14pt' : '8pt'};
  }
`

const Text = (props) => {

  return (
    props.textType == 'header' ?
      <StyledHeader
        mobileTextSize={props.mobileTextSize}
        textSize={props.textSize}
        textColor={props.textColor}
        padding={props.margin}
        margin={props.padding}
        upperCase={props.upperCase}
        lineHeight={props.lineHeight}
        bolder={props.bolder}
        center={props.center}
        className={props.className}>
        {props.children}
      </StyledHeader>
    :
      <StyledText
        textSize={props.textSize}
        textColor={props.textColor}
        padding={props.padding}
        margin={props.margin}
        upperCase={props.upperCase}
        lineHeight={props.lineHeight}
        center={props.center}
        className={props.className}>
          {props.children}
      </StyledText>
  )
}

export default Text
