/* eslint-disable */
import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const CheckContainer = styled.div`
  width: 100%;
`
const Spacer = styled.div`
  display: block;
  width: 100%;
  height: 10px;
`

const CheckText = styled.span `
  display: inline-block;
  color: #12233E;
`

const CheckMark = styled.span`
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;

  :after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #489B43;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

`

const DefaultErrorStyling = css`
  color: red;
  display: none;
  margin: 0 0 0 5px;
  padding: 0;
  font-size: 10pt;
  line-height: 10pt;
`

const Label = styled.label`
  width: 100%;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 10pt;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 50%;
    height: 60%;
    left: 0;
    z-index: 1000;

    :checked~span:after{
      display: block;
    }
  }

`
const CheckBox = (props) => {

  return (
    <CheckContainer>
      <Spacer />
      <Label htmlFor={props.id}>
        <input
          id={props.id}
          type={props.type}
          onChange={props.onChange}
          onClick={props.onClick}
          defaultChecked={props.defaultChecked}
        />
        <CheckMark />
        <CheckText htmlFor="radio">
          {props.children}
        </CheckText>
      </Label>
    </CheckContainer>
  )
}

export default CheckBox
