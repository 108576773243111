/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Formik } from 'formik';
import Recaptcha from 'react-recaptcha';
import Input from '../Common/Input/'
import CheckBox from '../Common/CheckBox/'
import Text from '../Common/Text/'
import HTMLSelect from '../Common/Select/'
import SubmitButton from '../Common/SubmitButton/'
import ReactGA from 'react-ga';
import sendEvent from '../../helpers/sendAnalytics';

import { Tween, Timeline } from 'react-gsap';

import {selectProvince} from '../Helpers/'
import {createCustomer, updateCustomer} from '../Queries/'

import Icon1 from '../../assets/images/icon1.svg'
import Icon2 from '../../assets/images/icon2.svg'
import Icon3 from '../../assets/images/icon3.svg'


import CloseIcon from '../../assets/images/cancel.svg'
import BackIcon from '../../assets/images/go-back.svg'


const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    @media(max-width: 769px){
      width: 100%;
      padding: 0 2%;
      :first-child{
        margin-top: 7%;
      }
    }
    @media(max-width: 479px){
      .e85ceny2 {
        margin-top: 12%;
      }
    }
  }
  @media(max-width: 769px){
    width: 100%;
    display: block;
  }
`

const Container = styled.div(props => ({
  width: props.width,
  padding: props.padding,
  margin: props.margin,
  position: 'relative'
}))

const SizedContainer = styled(Container)`
  @media(max-width: 769px){
    width: 100%;
    margin: 0;
    padding: 7% 2% 7% 4%;
  }
`

const ModalWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
  @media(max-width: 769px){
    display: none;
  }
`

const ModalContainer = styled.div`
  position: relative;
  width: 880px;
  min-height: 300px;
  height: auto;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  top: 25%;
  left: calc(50% - 440px);
  border-radius: 3px;
  padding: 0 0 25px 0;
  transition: 0.7s;
  transition-delay: 0.5s;
  opacity: 0;
  @media(max-width: 769px){
    width: 96%;
    left: 2%;
  }
`

const Step = styled.div`
  width: 100%;
`

const Progress = styled.progress`
  width: 100%;
  position: absolute;
  top: 0;
  display: block;
  border-radius: 3px;
  height: 4px;
  transition: width 5s ease;
  background-color: #e23d3f;

  ::-webkit-progress-value{
    background-color: #114875;
  }
  ::-webkit-progress-bar {
    background-color: #e23d3f;
  }
  @media(max-width: 769px){
    width:95%;
  }
`

const Label = styled.label`
  display: block;
  color: #12233E !important;
  font-weight: 300;
  margin: 0;
  padding: 0;
  font-size: 10pt;
  color: #9497A2
`
const Error = styled.span`
  display: inline;
  color: #ff0000;
  font-size: 9pt;
  font-weight: 300;
  margin-left: 5px;
  @media(max-width: 769px){
    display: block;
    margin-left: ${props => props.adjust ? '2%' : '0'};
  }
`

const ControlIcons = css`
  position: absolute;
  z-index: 1;
  width: 15px;

  cursor: pointer;
`

const SmallPrint = styled.span`
  color: #a3a3a3;
  line-height: 20px;
  font-size: 9pt;
  width: 90%;
  display: block;
  > p {
    margin: -1px 0 0 0;
    padding: 0;
  }
`

const GoBack = styled.img`
  ${ControlIcons}
  top: 22px;
  left: 0%;
  @media(max-width: 769px){
    right: 55px;
    left: auto;
    top: -35px;
  }
`
const Close = styled.img`
  ${ControlIcons}
  top: 23px;
  right: 15px;
  @media(max-width: 769px){
    top: -35px;
  }
`

const List = styled.ul`
  width: 100%;
  margin: 0 0 0 5px;
  padding: 0;
`
const ListItem = styled.li(props => ({
  'padding-left': '30px',
  'font-size': '16px',
  'font-weight': '500',
  'color': props.textColor != '' ? props.textColor : '#0272C4',
  'letter-spacing': '0',
  'line-height': '36px',
  'list-style-type': 'none',
  'background-size': '16px',
  'background-position': '0px 11px',
  'background-repeat': 'no-repeat',
  'background-image': 'url(' + props.icon + ')',
}))

const MobileShift = styled.span`
  position: absolute;
  top: 31px;
  @media(max-width: 769px){
    top: 10px;
    margin-left: 0px;
  }
`
const MobileMove = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media(max-width: 769px){
    display: block;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    > div {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
`

const ModalText = styled(Text)`
  @media(max-width: 769px){
    font-size: 10pt;
    line-height: 17pt;
    margin: 10px 0 0;
  }
`

const FormattedMessage = styled.p`
  font-size: 12pt;
`

const Spacer = styled.div`
  display: block;
  width: 100%;
  height: 25px;
`

const goBack = (props) => {
  let state = props.appState
  state.controls.modalStep = state.controls.modalStep - 1
  props.setValue('controls',state.controls)
}

const closeModal = (props) => {
  document.getElementsByTagName("body")[0].classList.remove('modalUp')
  let state = props.appState
  state.controls.modalStep = 1
  state.controls.modal = false
  props.setValue('controls',state.controls)
}

const verifyCallback = (response, props) => {
  if(response.length > 0){
    let state = props.appState
    state.controls.captcha = true
    props.setValue('controls', state.controls)
  }
}

const saveNewsletter = (props) => {
  let state = props.appState
  state.validations.newsletter = !state.validations.newsletter
  props.setValue('validations',state.validations)
}

const Modal = (props) => {

  const Provinces = [
    {value:'CT', label:'Connecticut'},
    {value:'ME', label:'Maine'},
    {value:'MA', label:'Massachusetts'},
    {value:'NH', label:'New Hampshire'},
    {value:'RI', label:'Rhode Island'},
    {value:'VT', label:'Vermont'}



  ]
  if(typeof document !== 'undefined'){
    var modalWindow = document.getElementById('modalWindow')
    props.appState.controls.modal && !props.appState.screens.bannerFinal ?
      modalWindow ?
        modalWindow.classList.add('makeVisible')
        :
        ''
    :
      modalWindow ?
        modalWindow.classList.remove('makeVisible')
        :
        ''
  }


  return (

      <React.Fragment>
        <ModalWrapper />
        <ModalContainer id="modalWindow">
          <Progress max="100" value={props.appState.customer.uuid == null ? props.appState.controls.modalStep * 33 : props.appState.controls.modalStep * 50} />
          { props.appState.controls.modalStep > 1 ? <GoBack src={BackIcon} onClick={() => { goBack(props)}} /> : '' }
          <Close src={CloseIcon} onClick={() => { closeModal(props)}} />

          { // modal step 1
            props.appState.controls.modalStep < 2 ?
            <Step>
              <FlexContainer>
                <SizedContainer
                  width="50%"
                  padding="5% 5% 0 2%">
                  <Text
                    textType="header"
                    textColor="#12233E"
                    textSize="14px"
                    lineHeight="0px"
                    margin="0"
                    padding="0"
                    upperCase={true}>
                    <MobileShift>
                      Step 1 of { props.appState.customer.uuid != null ? '2' : '3'}
                    </MobileShift>
                  </Text>
                  <ModalText
                    textColor="#12233E"
                    textSize="25px"
                    lineHeight="40px">
                    <br />You only need to do this once. Next time, all you’ll need to enter is your email address.
                  </ModalText>
                </SizedContainer>
                <SizedContainer
                  width="50%"
                  padding="5% 0 0 0">
                  <Formik
                    initialValues={{
                      firstname: props.appState.customer.firstname,
                      lastname: props.appState.customer.lastname,
                      zipcode: props.appState.customer.zipcode,
                      yearofbirth: props.appState.customer.yearofbirth,
                      phone:props.appState.customer.phone

                    }}
                    validate={values => {
                      let errors = {};

                      if(!values.firstname || values.firstname.length < 2) {
                        errors.firstname = 'Required';
                      }

                      if(!values.lastname || values.lastname.length < 2) {
                        errors.lastname = 'Required';
                      }

                      if(!values.zipcode) {
                        errors.zipcode = 'Required';
                      } else if (values.zipcode.length != 5 || isNaN(values.zipcode)){
                        errors.zipcode = "Invalid"
                      }


                      if (!values.yearofbirth) {
                        errors.yearofbirth = 'Required';
                      } else if((values.yearofbirth.length != 4 || isNaN(values.yearofbirth)) ||
                               ((values.yearofbirth < 1900) || (values.yearofbirth > 2001))){
                                errors.yearofbirth = 'Invalid'
                      }

                      if (!values.phone) {
                        errors.phone = "Please enter a valid number";
                      } else if(!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(String(values.phone))){
                        errors.phone = "You must enter a valid phone number"
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      let state = props.appState
                      state.customer.firstname = values.firstname
                      state.customer.lastname = values.lastname
                      state.customer.zipcode = values.zipcode
                      // disabled this as its for US contest
                      //state.customer.state = selectProvince(values.zipcode, props.locale)
                      state.customer.phone = values.phone
                      state.customer.yearofbirth = values.yearofbirth
                        props.setValue('customer',state.customer)
                      state.controls.modalStep = 2
                        props.setValue('controls',state.controls)

                      if(!state.tracking.modal2){
                        if(props.appState.customer.uuid == null){
                          ReactGA.pageview('/patriots-2019-new-second-modal');
                        } else {
                          ReactGA.pageview('/patriots-2019-existing-second-modal');
                        }
                        state.tracking.modal2 = true
                      }

                      props.setValue('tracking',state.tracking)

                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      })

                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      //form here
                      <form onSubmit={handleSubmit}>
                        <FlexContainer>
                          <SizedContainer
                            width="45%">
                            <Label htmlFor="firstname">
                              First name
                              <Error>{errors.firstname && touched.firstname && errors.firstname}</Error>
                            </Label>
                            <Input
                              type="text"
                              name="firstname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstname}
                              width="95%"
                            />
                          </SizedContainer>
                          <SizedContainer
                            width="55%">
                            <Label htmlFor="lastname">
                              Last name
                              <Error>{errors.lastname && touched.lastname && errors.lastname}</Error>
                            </Label>
                            <Input
                              type="text"
                              name="lastname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastname}
                              width="85%"
                            />
                          </SizedContainer>
                          <SizedContainer
                            width="45%">
                            <Label htmlFor="zipcode">
                              Zip Code
                              <Error>{errors.zipcode && touched.zipcode && errors.zipcode}</Error>
                            </Label>
                            <Input
                              type="text"
                              name="zipcode"
                              mask="zipcode"
                              upperCase={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.zipcode}
                              width="95%"
                            />
                          </SizedContainer>
                          <SizedContainer
                            width="55%">
                            <Label htmlFor="yearofbirth">
                              Year of birth
                              <Error>{errors.yearofbirth && touched.yearofbirth && errors.yearofbirth}</Error>
                            </Label>
                            <Input
                              type="text"
                              name="yearofbirth"
                              mask="year"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.yearofbirth}
                              width="85%"
                            />
                          </SizedContainer>
                          <SizedContainer
                            width="100%">
                            <Label htmlFor="phone">
                              Telephone
                              <Error>{errors.phone && touched.phone && errors.phone}</Error>
                            </Label>
                            <Input
                              type="text"
                              name="phone"
                              mask="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              width="91.5%"
                            />
                          </SizedContainer>
                          <Spacer />
                          <SubmitButton
                            type="submit"
                            disabled={false}>
                            Continue
                          </SubmitButton>
                        </FlexContainer>
                      </form>

                    )}
                  </Formik>
                </SizedContainer>
              </FlexContainer>
            </Step>
            :
            ''
          }


          { // modal step 2
            props.appState.controls.modalStep == 2 ?
            <Step>
              <FlexContainer>
                <SizedContainer
                  width="50%"
                  padding="5% 5% 0 2%">
                  <Text
                    textType="header"
                    textColor="#12233E"
                    textSize="14px"
                    lineHeight="0px"
                    margin="0"
                    padding="0"
                    upperCase={true}>
                    <MobileShift>
                      Step 2 of { props.appState.customer.uuid != null ? '2' : '3'}
                    </MobileShift>
                  </Text>
                  <ModalText
                    textColor="#12233E"
                    textSize="25px"
                    lineHeight="40px">
                    <br />We just need a few more details to enter you into the contest.
                  </ModalText>
                </SizedContainer>
                <SizedContainer
                  width="50%"
                  padding="5% 0 0 0">
                  <Formik
                    initialValues={{
                      address: props.appState.customer.address,
                      address2: props.appState.customer.address2,
                      city: props.appState.customer.city,
                      state:props.appState.customer.state.value
                    }}
                    validate={values => {
                      let errors = {};

                      if(!values.address) {
                        errors.address = 'You must enter a valid address'
                      }

                      if(!props.appState.customer.state) {

                        errors.state = 'You must select a state'
                      }

                      if(!values.city) {
                        errors.city = 'You must enter a city'
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {

                      let state = props.appState
                      let st = {
                        label: values.state,
                        value:values.state
                      }
                      state.customer.address = values.address
                      state.customer.address2 = values.address2
                      state.customer.city = values.city
                      props.setValue('customer',state.customer)

                      if(props.appState.customer.uuid != null){

                        state.controls.modal = false
                        props.setValue('controls',state.controls)
                        state.screens.bannerFinal = true
                        state.screens.contentFinal = true
                        props.setValue('screens',state.screens)

                        updateCustomer(props.appState,true)

                        if(!state.tracking.thanks){
                          ReactGA.pageview('/patriots-2019-existing-thanks');
                          state.tracking.thanks = true
                        }

                        document.getElementsByTagName("body")[0].classList.remove('modalUp');
                        window.scroll({
                          top: 0,
                          left: 0,
                          behavior: 'smooth'
                        })
                      } else {
                        if(!state.tracking.modal3){
                          ReactGA.pageview('/patriots-2019-new-third-modal');
                          state.tracking.modal3 = true
                        }
                        state.controls.modalStep = 3
                        props.setValue('controls',state.controls)
                      }
                      props.setValue('tracking',state.tracking)
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      })


                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      //form here
                      <form onSubmit={handleSubmit}>
                        <Label htmlFor="address">
                          Address 1
                          <Error>{errors.address && touched.address && errors.address}</Error>
                        </Label>
                        <Input
                          type="text"
                          name="address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          width="90%"
                        />
                      <Label htmlFor="address2">
                          Address 2
                          <Error>{errors.address2 && touched.address2 && errors.address2}</Error>
                        </Label>
                        <Input
                          type="text"
                          name="address2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address2}
                          width="90%"
                        />
                        <FlexContainer>
                          <MobileMove>
                            <SizedContainer
                              width="50%">
                              <Label htmlFor="city">
                                City
                                <Error>{errors.city && touched.city && errors.city}</Error>
                              </Label>
                              <Input
                                type="text"
                                name="city"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.city}
                                width="90%"
                              />
                            </SizedContainer>
                            <SizedContainer
                              width="50%">
                              <Label htmlFor="state">
                                State
                                <Error>{errors.state && touched.state && errors.state}</Error>
                              </Label>
                              <HTMLSelect
                                  stateName="state"
                                  options={Provinces}
                                  setValue={props.setValue}
                                  appState={props.appState}
                                  value={props.appState.customer.state}
                                  />
                            </SizedContainer>
                          </MobileMove>
                        </FlexContainer>
                        <Spacer />
                        <SubmitButton
                          type="submit"
                          disabled={false}>
                          { props.appState.customer.uuid == null ?
                            'Continue'
                            :
                            'Submit'
                          }
                        </SubmitButton>
                      </form>
                    )}
                  </Formik>
                </SizedContainer>
              </FlexContainer>
            </Step>
            :
            ''
            // end step 2
          }



          { // modal step 3
            props.appState.controls.modalStep > 2 ?
            <Step>
              <FlexContainer>
                <SizedContainer
                  width="50%"
                  padding="5% 5% 0 2%">
                  <Text
                    textType="header"
                    textColor="#12233E"
                    textSize="14px"
                    lineHeight="0px"
                    margin="0"
                    padding="0"
                    upperCase={true}>
                    <MobileShift>
                      Step 3 of 3
                    </MobileShift>
                  </Text>
                  <ModalText
                    textColor="#12233E"
                    textSize="25px"
                    lineHeight="40px">
                    <br />Last step before you’re entered into the sweepstakes.
                  </ModalText>

                </SizedContainer>
                <SizedContainer
                  width="50%"
                  padding="5% 0 0 0">
                  <Formik
                    initialValues={{
                      captcha:props.appState.controls.captcha,
                      newsletter:props.appState.validations.newsletter
                    }}
                    validate={values => {
                      let errors = {};

                      if(!props.appState.controls.captcha){
                        errors.captcha = 'You must click the reCaptcha'
                      }

                      //if(props.appState.customer.uuid == null && !values.newsletter ) {
                      //  errors.newsletter = 'You must agree to the Communication Policy'
                      //}

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      let state = props.appState
                      let st = {
                        label: 'test',
                        value:values.state
                      }
                      //state.validations.newsletter = true
                      props.setValue('validations',state.validations)
                      state.controls.modal = false
                      props.setValue('controls',state.controls)
                      state.screens.bannerFinal = true
                      state.screens.contentFinal = true
                      props.setValue('screens',state.screens)


                      createCustomer(props.appState,(uuid) => {
                        state.customer.uuid = uuid
                        props.setValue('customer',state.customer)
                      })


                      if(!state.tracking.thanks){
                        ReactGA.pageview('/patriots-2019-new-thanks');
                        state.tracking.thanks = true
                        props.setValue('tracking',state.tracking)
                      }

                      document.getElementsByTagName("body")[0].classList.remove('modalUp');
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      })

                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      //form here
                      <form onSubmit={handleSubmit}>

                        <Error adjust={true}>{errors.newsletter && touched.newsletter && errors.newsletter}</Error>
                        <CheckBox
                          id="newsletter"
                          type="checkbox"
                          onChange={handleChange}
                          onClick={() => { saveNewsletter(props) }}
                          defaultChecked={true}
                          >
                          Yes! I would like to subscribe to SCOTTIES<sup>®</sup> emails to be among the first to know about savings, contests, product news and much more.<br /><br />
                        </CheckBox>

                        <Error>{errors.captcha && touched.captcha && errors.captcha}</Error>
                          <Recaptcha
                            sitekey="6LfpKZ0UAAAAAFZHEg0VwIJdtmH74Dj48n0gflWH"
                            verifyCallback={(response) => verifyCallback(response, props)}
                          />
                        <SubmitButton
                          type="submit"
                          disabled={false}>
                          Submit
                        </SubmitButton>
                      </form>
                    )}
                  </Formik>
                </SizedContainer>
              </FlexContainer>
            </Step>
            :
            ''
            // end step 3
          }




        </ModalContainer>
      </React.Fragment>
  )
}

export default Modal
