/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Text from '../Common/Text/'
import sendEvent from '../../helpers/sendAnalytics';
import IsMobile from '../../components/common/IsMobile'
import IsDesktop from '../../components/common/IsDesktop'
import Callout from '../kidscallout.js';

import ContentBackground from '../../assets/patriots-images/content-background.png'
import ContentMobile1 from '../../assets/patriots-images/content-mobile-1.png'
import ContentMobile2 from '../../assets/patriots-images/content-mobile-2.jpg'

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media(max-width: 769px){
    width: 100%;
    display: block;
  }
`

const Container = styled.div(props => ({
  'width': props.width,
  'padding': props.padding,
  'margin': props.margin,
  'position': 'relative',
  'background-color': props.backgroundColor != '' ? props.backgroundColor : '#fff',
}))

const SizedContainer = styled(Container)`
  img {
    width: 100%;
  }
  @media(max-width: 769px){
    width: 100%;
    margin: 0;
    padding: 7% 2%;

    img {
      width: auto;
    }
  }
`

const ResponsiveSizedContainer = styled(SizedContainer)`
  @media(max-width: 769px){
    width: 100%;
    margin: 0;
    padding: 1% 2% 0;
  }
`



const BlueLink = styled.a`
  cursor: pointer;
  display: inline-block;
  background: #4D8DCD;
  border-radius: 50px;
  font-size: 14px;
  color: #FFFFFF !important;
  letter-spacing: 0;
  text-align: center;
  padding: 15px 45px;
  margin: 50px 0;
  > a {
    color: #FFFFFF !important;
    text-decoration: none;
  }
`

const CouponLink = styled.a`

  background-color: #e23e3f;
  text-transform: uppercase;
  color: #fff !important;
  padding: 15px 45px;
  display: inline-block;
  margin: 25px 0;
  text-align: center;
  font-weight: 300;
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  cursor: pointer;
  position: absolute;
  left: 55%;
  top: 68%;
  > a {
    color: #e23e3f !important;
    text-decoration: none;
  }
  @media(max-width: 769px){
    position: relative;
    margin: 0 20% 50px;
    left: 0%;
    top: auto;
  }

`


const ContentSection = styled.div`
  position: relative;
  width: 100%;
  display: block;
  margin-top: 25%;
  @media(max-width: 1480px){
    margin-top: 20%;
  }
  @media(max-width: 769px){
    margin-top: 40%;
  }
`
const BackBoxes = styled.img`
  width: 85%;
  margin: 0;
  @media(max-width: 769px){
    width: 100%;
    :nth-child(1){
      width: 80%;
      margin: 0px 10% 50px;
    }
  }
`


const Content = (props) => {

    return (
      <React.Fragment>
        {
           props.appState.screens.bannerFinal ?
          <React.Fragment>
            <IsDesktop>
              <ContentSection>
                <BackBoxes src={ContentBackground} />
                <CouponLink
                  href='/patriotsboxes'
                  onclick={() => sendEvent({
                    ga: {
                      category: 'Patriots2019',
                      action: 'Click',
                      label: 'SeeCollection'
                    }
                  })}
                >See Collection</CouponLink>
              </ContentSection>
            </IsDesktop>
            <IsMobile>
              <ContentSection>
                <BackBoxes src={ContentMobile1} />
                <CouponLink
                  href='/patriotsboxes'
                  onclick={() => sendEvent({
                    ga: {
                      category: 'Patriots2019',
                      action: 'Click',
                      label: 'SeeCollection'
                    }
                  })}
                >See Collection</CouponLink>
                <BackBoxes src={ContentMobile2} />
              </ContentSection>
            </IsMobile>
            <br /><br />
            <Callout />
          </React.Fragment>
          :
          ''
        }
      </React.Fragment>
    )
}

export default Content
