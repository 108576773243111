/* eslint-disable */
import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

/*** props ***/

// textType: normal, header
// textSize: custom value in px or pt, default it 12pt;
// textColor: Custom color, default black
// padding: adjust the padding of the element
// margin: adjust the margins of the element


const StyledButton = styled.button`
  background-color: #E23D3F;
  padding: 15px 55px;
  color:#fff;
  border: 0;
  font-size:11pt;
  border-radius:2px;
  margin:-15px 0 20px;
  cursor:pointer;
  outline:none;
  display: inline-block;
  text-transform: uppercase;
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  transition: 0.5s;
  :hover{
    background-color: #bf2224;
  }
  @media(min-width: 619px) and (max-width: 769px){
    margin-bottom:35px;
    margin-left: ${props => props.adjust ? '0px' : '14px'};
    padding: ${props => props.adjust ? '20px 45px' : '20px 55px'};
  }
  @media(max-width: 619px){
    margin-bottom:35px;
    margin-left: ${props => props.adjust ? '0px' : '5px'};
    padding: ${props => props.adjust ? '20px 45px' : '20px 55px'};
  }
`


const SubmitButton = (props) => {

  return (
      <StyledButton
        disabled={props.disabled}
        adjust={props.adjust}>
        {props.children}
      </StyledButton>
  )
}

export default SubmitButton
