/* eslint-disable */
import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import InputMask from 'react-input-mask'

/*** props ***/

// textType: normal, header
// textSize: custom value in px or pt, default it 12pt;
// textColor: Custom color, default black
// padding: adjust the padding of the element
// margin: adjust the margins of the element

const StyledInput = styled(InputMask)`
  display: block;
  border: 1px solid #bfbfbf;
  font-size: 13pt;
  border-radius: 5px;
  padding:  18px 15px;
  width: ${props => props.width != '' ? props.width : '95%'};
  margin: 0px 0 10px;
  color: #535353;
  text-transform: ${props => props.upperCase ? 'uppercase' : 'none'};
  :focus {
    outline: none;
  }
  @media(max-width: 769px){
    width: 95%;
  }
`

const Input = (props) => {

  let mask = ""
  let maskChar= ''
  if(props.mask == 'phone'){
    mask = '999-999-9999'
    maskChar= '#'
  }
  if(props.mask == 'postalcode'){
    mask = 'a9a 9a9'
    maskChar= '_'
  }
  if(props.mask == 'zipcode'){
    mask = '99999'
    maskChar= '#'
  }
  if(props.mask == 'year'){
    mask = '9999'
    maskChar= '#'
  }

  return (
      <StyledInput
        mask={mask}
        maskChar={maskChar}
        type={props.type}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        width={props.width}
        upperCase={props.upperCase}
      />
  )
}

export default Input
